import { useState } from "react";
import { Filters, filterFields } from "../Filters";
import { Card } from "../Card";
import { Register } from "../Register";
import { useProductsContext } from "../../contexts/ProductsContext";
import { useMediaQuery } from "../../hooks/use-media-query";

const formFields = [
    {
        label: "Nome",
        name: "name",
        type: "text",
        width: "300",
        readOnly: true,
    },
    {
        label: "Preço",
        name: "price",
        type: "number",
        width: "100",
        readOnly: true,
    },
    {
        label: "Data da última entrega",
        name: "date_from_last_delivery",
        type: "date",
        width: "190",
        readOnly: true,
    },
    {
        label: "Estoque",
        name: "stock",
        type: "number",
        width: "100",
        readOnly: true,
    },
    {
        label: "Descrição",
        name: "description",
        type: "textarea",
        width: "650",
        readOnly: true,
    },
];

export const Produtos = () => {
    const [state, actions] = useProductsContext();
    const [filters, setFilters] = useState({});
    const filteredProducts = filterFields(state, filters);

    const small = useMediaQuery("(max-width: 768px)");

    return (
        <div>
            <h1>Produtos</h1>
            <div className={`d-flex ${small ? "flex-column" : ""}`}>
                <Filters setFilters={setFilters} />
                <Register onHandleCreateRegister={actions.createProduct} registerName="Cadastro de Produto" formFields={formFields} />
            </div>
            <ul className="d-flex-column list-style-none mt-5 p-0 max-width-large">
                {filteredProducts.map((product) => (
                    <Card
                        key={`product_parent_${product.id}`}
                        onHandleRemoveCard={actions.removeProduct}
                        onHandleUpdateCard={actions.updateProduct}
                        content={product}
                        formFields={formFields}
                    />
                ))}
            </ul>
        </div>
    );
};
