import * as actionTypes from "./action-types";
const initialState = [];

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_PROCEDURES:
        case actionTypes.GET_PROCEDURE:
            return Array.isArray(action.payload) ? action.payload : [];
        case actionTypes.CREATE_PROCEDURE:
            return [action.payload, ...state];
        case actionTypes.UPDATE_PROCEDURE:
            return state.map((procedure) => {
                if (procedure.id === action.payload.id) {
                    return action.payload;
                }
                return procedure;
            });
        case actionTypes.SET_PROCEDURES:
            return action.payload;
        case actionTypes.DELETE_PROCEDURE:
            return state.filter((procedure) => procedure.id !== action.payload);
        default:
            return state;
    }
};
