import { useEffect, useState } from 'react';

export const useMediaQuery = (query, initialValue = false) => {
    const [match, setMatch] = useState(initialValue);

    useEffect(() => {
        const media = window.matchMedia(query);

        const handleChange = () => {
            setMatch(media.matches);
        };

        media.addEventListener('change', handleChange);
        setMatch(media.matches);

        return () => {
            media.removeEventListener('change', handleChange);
        };
    }, [query]);

    return match;
};
