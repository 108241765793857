import * as actionTypes from "./action-types";

const removeProduct = async (id) => {
    const response = await fetch(`https://test-donaflorinda.netlify.app/.netlify/functions/delete-product/?id=${id}`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
    const data = await response.json();
    return data;
};

const updateProduct = async (id, body) => {
    const response = await fetch(`https://test-donaflorinda.netlify.app/.netlify/functions/update-product/?id=${id}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(body),
    });
    const data = await response.json();
    return data;
};

const createProduct = async (body) => {
    const response = await fetch("https://test-donaflorinda.netlify.app/.netlify/functions/create-product", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(body),
    });
    const data = await response.json();
    return data;
};

const getProducts = async () => {
    try {
        const response = await fetch("https://test-donaflorinda.netlify.app/.netlify/functions/get-products", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        const data = await response.json();
        return data;
    } catch (error) {
        localStorage.removeItem("token");
        window.location.href = "/login";
    }
};

export const buildActions = (dispatch) => {
    return {
        removeProduct: async (id) => {
            await removeProduct(id);
            dispatch({ type: actionTypes.DELETE_PRODUCT, payload: id });
        },
        updateProduct: async (id, body) => {
            const data = await updateProduct(id, body);
            dispatch({ type: actionTypes.UPDATE_PRODUCT, payload: data });
        },
        createProduct: async (body) => {
            const data = await createProduct(body);
            dispatch({ type: actionTypes.CREATE_PRODUCT, payload: data });
        },
        getProducts: async () => {
            const data = await getProducts();
            dispatch({ type: actionTypes.GET_PRODUCTS, payload: data });
        },
        setProducts: (products) => {
            dispatch({ type: actionTypes.SET_PRODUCTS, payload: products });
        },
    };
};
