import { useCallback, useEffect, useState } from "react";

export const useAsync = (asyncFunction, invoke = false) => {
    const [result, setResult] = useState(null);
    const [status, setStatus] = useState("");
    const [error, setError] = useState("idle");

    const run = useCallback(() => {
        setResult(null);
        setError(null);
        setStatus("pending");

        return asyncFunction()
            .then((response) => {
                setResult(response);
                setStatus("success");
            })
            .catch((error) => {
                setError(error);
                setStatus("error");
            });
    }, [asyncFunction]);

    useEffect(() => {
        if (invoke) {
            run();
        }
    }, [invoke, run]);

    return [result, status, error, run];
};
