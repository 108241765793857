import { useState } from "react";
import Modal from "react-modal";
import "../../styles/variables.scss";
import { Separator } from "../Separator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faX } from "@fortawesome/free-solid-svg-icons";
import { Input } from "../Input";
import "react-toastify/dist/ReactToastify.css";
import { useToast } from "../../hooks/use-toast";
import { useForm } from "react-hook-form";

export const Register = ({ registerName, formFields, onHandleCreateRegister = null }) => {
    const [isRegisterModalIsOpen, setIsRegisterModalIsOpen] = useState(false);
    const [isLoadingRequest, setIsLoadingRequest] = useState(false);

    const defaultValues = formFields.reduce((acc, field) => {
        if (field.type !== "hidden") {
            acc[field.name] = "";
        }
        return acc;
    }, {});

    const { control, register, handleSubmit } = useForm({ defaultValues });

    const { notify } = useToast();

    const onSubmit = async (data) => {
        try {
            setIsLoadingRequest(true);
            notify("Criando...", "info");
            formFields.forEach((field) => {
                if (field.type === "number") {
                    data[field.name] = +data[field.name];
                }
                if (field.type === "multiselect") {
                    if (data.select) {
                        data[field.name] = data.select.map((item) => item.value);
                        delete data[`${field.name}Value`];
                        delete data.select;
                    }
                }
            });
            for (let key in data) {
                if (data[key] === "true" || data[key] === "false") {
                    data[key] = data[key] === "true";
                }
            }
            await onHandleCreateRegister(data);
            setIsLoadingRequest(false);
            notify("Salvo com sucesso!", "success");
            setIsRegisterModalIsOpen(false);
        } catch (error) {
            setIsLoadingRequest(false);
            notify("Erro ao salvar, tente novamente mais tarde!", "error");
        }
    };

    const handleFormFields = () => {
        formFields.forEach((field) => {
            if (field.type === "multiselect") {
                field.multiSelectValue = [];
            }
        });
    };

    Modal.setAppElement("#root");
    return (
        <div className="d-flex-column">
            <label>Cadastro</label>
            <button
                disabled={isLoadingRequest}
                type="button"
                onClick={() => {
                    setIsRegisterModalIsOpen(true);
                    handleFormFields();
                }}
                className="transition-all circular-button flex-center border-primary-color border-solid rounded-circle background-primary-color">
                <FontAwesomeIcon icon={faPlus} />
            </button>
            <Modal
                isOpen={isRegisterModalIsOpen}
                onRequestClose={() => setIsRegisterModalIsOpen(false)}
                contentLabel="Example Modal"
                style={{
                    overlay: {
                        backgroundColor: "rgba(0, 0, 0, 0.75)",
                        zIndex: "3",
                    },
                    content: {
                        width: "800px",
                        minHeight: "300px",
                        top: "50%",
                        left: "50%",
                        right: "auto",
                        bottom: "auto",
                        transform: "translate(-50%, -50%)",
                        backgroundColor: "var(--background-primary-color)",
                        position: "relative",
                        border: "1px solid var(--border-primary-color)",
                        display: "flex",
                        justifyContent: "start",
                        flexDirection: "column",
                        alignItems: "center",
                        borderRadius: "20px",
                    },
                }}>
                <form className="d-flex-column align-start-center jusityfjustify-content-start" onSubmit={handleSubmit(onSubmit)}>
                    <h2 className="text-uppercase text-center text-primary-color fw-bold">{registerName}</h2>
                    <Separator width="75" />
                    <div className="d-flex flex-wrap mt-4 justify-content-start">
                        {formFields.map((field, index) => (
                            <div key={`form_field_${index}`} className={`${field.type === "hidden" ? "d-none" : "mx-3 my-2"}`}>
                                <Input control={control} register={register} inputConfig={field} />
                            </div>
                        ))}
                    </div>
                    <div className="align-self-end mt-4">
                        <button
                            disabled={isLoadingRequest}
                            type="button"
                            onClick={() => setIsRegisterModalIsOpen(false)}
                            className="text-black border-0 rounded-pill background-cancel-button-color fs-7 px-4 me-4">
                            Cancelar
                        </button>
                        <button
                            disabled={isLoadingRequest}
                            type="submit"
                            className="text-black border-0 rounded-pill background-confirm-button-color fs-7 px-4">
                            Salvar
                        </button>
                    </div>
                    <button
                        disabled={isLoadingRequest}
                        type="button"
                        className="close-button-modal position-absolute bg-transparent border-0 fs-4 custom-button"
                        onClick={() => setIsRegisterModalIsOpen(false)}>
                        <FontAwesomeIcon icon={faX} />
                    </button>
                </form>
            </Modal>
        </div>
    );
};
