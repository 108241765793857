import * as actionTypes from "./action-types";

const removeRoom = async (id) => {
    const response = await fetch(`https://test-donaflorinda.netlify.app/.netlify/functions/delete-room/?id=${id}`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
    const data = await response.json();
    return data;
};

const updateRoom = async (id, body) => {
    const response = await fetch(`https://test-donaflorinda.netlify.app/.netlify/functions/update-room/?id=${id}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(body),
    });
    const data = await response.json();
    return data;
};

const createRoom = async (body) => {
    try {
        const response = await fetch("https://test-donaflorinda.netlify.app/.netlify/functions/create-room", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify(body),
        });
        const data = await response.json();
        return data;
    } catch (error) {
        localStorage.removeItem("token");
        window.location.href = "/login";
    }
};

const getRooms = async () => {
    const response = await fetch("https://test-donaflorinda.netlify.app/.netlify/functions/get-rooms", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
    const data = await response.json();
    return data;
};

const removeReserve = async (id, room_id) => {
    const response = await fetch(`https://test-donaflorinda.netlify.app/.netlify/functions/delete-reserve/?id=${id}&room_id=${room_id}`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
    const data = await response.json();
    return data;
};

const updateReserve = async (id, body) => {
    const response = await fetch(`https://test-donaflorinda.netlify.app/.netlify/functions/update-reserve/?id=${id}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(body),
    });
    const data = await response.json();
    return data;
};

const createReserve = async (body) => {
    try {
        const response = await fetch("https://test-donaflorinda.netlify.app/.netlify/functions/create-reserve", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify(body),
        });
        const data = await response.json();
        return data;
    } catch (error) {
        localStorage.removeItem("token");
        window.location.href = "/login";
    }
};

const getReservations = async () => {
    const response = await fetch("https://test-donaflorinda.netlify.app/.netlify/functions/get-reservations", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
    const data = await response.json();
    return data;
};

export const buildActions = (dispatch) => {
    return {
        removeRoom: async (id) => {
            await removeRoom(id);
            dispatch({ type: actionTypes.DELETE_ROOM, payload: id });
        },
        updateRoom: async (id, body) => {
            const data = await updateRoom(id, body);
            dispatch({ type: actionTypes.UPDATE_ROOM, payload: data });
        },
        createRoom: async (body) => {
            const data = await createRoom(body);
            dispatch({ type: actionTypes.CREATE_ROOM, payload: data });
        },
        getRooms: async () => {
            const data = await getRooms();
            dispatch({ type: actionTypes.GET_ROOMS, payload: data });
        },
        setRooms: (rooms) => {
            dispatch({ type: actionTypes.SET_ROOMS, payload: rooms });
        },
        removeReserve: async (id, room_id) => {
            await removeReserve(id, room_id);
            dispatch({ type: actionTypes.DELETE_RESERVE, payload: id });
        },
        updateReserve: async (id, body) => {
            const data = await updateReserve(id, body);
            dispatch({ type: actionTypes.UPDATE_RESERVE, payload: data });
        },
        createReserve: async (body) => {
            const data = await createReserve(body);
            dispatch({ type: actionTypes.CREATE_RESERVE, payload: data });
            return data;
        },
        getReservations: async () => {
            const data = await getReservations();
            dispatch({ type: actionTypes.GET_RESERVATIONS, payload: data });
        },
        setReservations: (rooms) => {
            dispatch({ type: actionTypes.SET_RESERVATIONS, payload: rooms });
        },
    };
};
