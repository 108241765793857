import { NavLink } from "react-router-dom";

import logo from "../../assets/logo.png";
import { Separator } from "../../components/Separator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faHamburger, faX } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

export const Navbar = ({ menus }) => {
    const [isOpen, setIsOpen] = useState(true);

    const toggleIsOpen = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav className={`background-secondary-color d-flex align-items-start border-right border-w-2 ${isOpen ? "p-4" : "p-0"}`}>
            {isOpen && (
                <div className="d-flex-column align-items-start h-100 justify-content-between">
                    <div className="d-flex-column">
                        <div className="d-flex align-items-center">
                            <NavLink to="/login">
                                <img
                                    className="max-width-image-nav border-w-1 border-primary-color border-solid rounded-circle"
                                    src={logo}
                                    alt="Logo"
                                />
                            </NavLink>
                            <span className="text-primary-color fw-bold fs-4 ms-3">Dona Florinda</span>
                        </div>
                        <Separator width="75" />
                        <ul className="flex-start flex-column list-style-none ps-2">
                            {menus.map((menu, index) => (
                                <li key={`menu_${index}`} className="mb-3">
                                    <NavLink
                                        className={({ isActive }) => `text-primary-color text-decoration-none fs-5 ${isActive ? "fw-bold" : ""}`}
                                        to={menu.link}>
                                        {menu.title}
                                    </NavLink>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <a target="_blank" rel="noopener noreferrer" href="/LGPD.pdf" className="mt-3 link-decoration-effect align-self-center">
                        <span className="text-primary-color fs-7">Termos LGPD</span>
                    </a>
                </div>
            )}
            <button
                type="button"
                onClick={() => toggleIsOpen()}
                className={`bg-transparent border-0 fs-5 custom-button close-button-modal-nav nav-button ${
                    isOpen ? "open" : "position-absolute top-1 left-1"
                }`}>
                {isOpen ? <FontAwesomeIcon icon={faX} /> : <FontAwesomeIcon icon={faBars} />}
            </button>
        </nav>
    );
};
