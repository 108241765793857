import * as actionTypes from "./action-types";
const initialState = [];

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_PRODUCTS:
        case actionTypes.GET_PRODUCT:
            return Array.isArray(action.payload) ? action.payload : [];
        case actionTypes.CREATE_PRODUCT:
            return [action.payload, ...state];
        case actionTypes.UPDATE_PRODUCT:
            return state.map((product) => {
                if (product.id === action.payload.id) {
                    return action.payload;
                }
                return product;
            });
        case actionTypes.SET_PRODUCTS:
            return action.payload;
        case actionTypes.DELETE_PRODUCT:
            return state.filter((product) => product.id !== action.payload);
        default:
            return state;
    }
};
