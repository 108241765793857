import { useEffect, useState } from "react";
import { Card } from "../Card";
import { Filters, filterFields } from "../Filters";
import { Register } from "../Register";
import { useProceduresContext } from "../../contexts/ProceduresContext";
import { useAnimalsContext } from "../../contexts/AnimalsContext";
import { useToast } from "../../hooks/use-toast";
import { useMediaQuery } from "../../hooks/use-media-query";

const formFields = [
    {
        label: "Animal",
        name: "animals",
        type: "multiselect",
        options: [],
        multiSelectValue: [],
        width: "300",
        readOnly: true,
    },
    {
        label: "Data",
        name: "procedure_date",
        type: "date",
        width: "150",
        readOnly: true,
    },
    {
        label: "Horário início",
        name: "datetime_start",
        type: "time",
        width: "130",
        readOnly: true,
    },
    {
        label: "Horário término",
        name: "datetime_end",
        type: "time",
        width: "150",
        readOnly: true,
    },
    {
        label: "Procedimento",
        name: "name",
        type: "text",
        width: "150",
        readOnly: true,
    },
    {
        label: "Método de pagamento",
        name: "payment_type",
        type: "dropdown",
        options: [
            { label: "PIX", value: "PIX" },
            { label: "Dinheiro", value: "Dinheiro" },
            { label: "Crédito", value: "Crédito" },
            { label: "Débito", value: "Débito" },
        ],
        width: "180",
        readOnly: true,
    },
    {
        label: "Valor",
        name: "payment_value",
        type: "number",
        width: "120",
        readOnly: true,
    },
    {
        label: "Pago",
        type: "dropdown",
        name: "paid",
        options: [
            { label: "Sim", value: true },
            { label: "Não", value: false },
        ],
        width: "80",
    },
];

const registerFormFields = [
    ...formFields,
    {
        label: "Pacote de banhos",
        name: "shower_package",
        type: "dropdown",
        options: [
            { label: "Não", value: false },
            { label: "Sim", value: true },
        ],
        width: "130",
        readOnly: true,
    },
    {
        label: "Intervalo de banho",
        name: "shower_interval",
        type: "number",
        width: "150",
    },
    {
        label: "Dias de pacote de banho",
        name: "shower_package_days",
        type: "number",
        width: "190",
    },
];

const customFilters = [
    {
        label: "Procurar por nome",
        name: "filter_search_name",
        type: "text",
        width: "200",
    },
    {
        label: "Pago",
        type: "dropdown",
        name: "filter_paid",
        options: [
            { label: "Todos", value: "" },
            { label: "Sim", value: true },
            { label: "Não", value: false },
        ],
        width: "130",
    },
];

export const Procedimentos = () => {
    const [state, actions] = useProceduresContext();
    const [animalsState] = useAnimalsContext();
    const [filters, setFilters] = useState({});

    const customFilterCallback = (procedure) => {
        let matches = true;

        const filter: any = filters;

        if (filter.filter_paid !== null && filter.filter_paid !== "" && filter.filter_paid !== undefined) {
            matches = matches && procedure.paid === filter.filter_paid;
        }

        if (filter.filter_search_name) {
            matches =
                matches && procedure.animalsValue.some((animal) => animal.label.toLowerCase().includes(filter.filter_search_name.toLowerCase()));
        }

        return matches;
    };

    const filteredProcedures = filterFields(state, filters, customFilterCallback);

    const { notify } = useToast();

    const small = useMediaQuery("(max-width: 768px)");

    useEffect(() => {
        formFields.forEach((field) => {
            if (field.name === "animals") {
                field.options = animalsState.map((animal) => ({
                    label: animal.name,
                    value: animal.id,
                }));
            }
        });
    }, [animalsState]);

    const cardBlurb = (content, handlePayment) => (
        <button
            type="button"
            disabled={content.paid}
            onClick={() => handlePayment()}
            className="text-black border-0 rounded-pill background-confirm-button-color fs-7 px-4">
            {content.paid ? "Pago" : "Pagar"}
        </button>
    );

    const handleCreateProcedure = async (data) => {
        try {
            await actions.createProcedure(data);
            if (data.shower_package) {
                await actions.getProcedures();
            }
        } catch (error) {
            notify("Erro ao criar procedimento, tente novamente mais tarde!", "error");
        }
    };

    return (
        <div>
            <h1>Procedimentos</h1>
            <div className={`d-flex ${small ? "flex-column" : ""}`}>
                <Filters customFilters={customFilters} setFilters={setFilters} />
                <Register onHandleCreateRegister={handleCreateProcedure} registerName="Cadastro de Procedimento" formFields={registerFormFields} />
            </div>
            <ul className="d-flex-column list-style-none mt-5 p-0 max-width-large">
                {filteredProcedures.map((procedure) => (
                    <Card
                        onHandleRemoveCard={actions.removeProcedure}
                        onHandleUpdateCard={actions.updateProcedure}
                        key={`procedure_parent_${procedure.id}`}
                        havePayment={true}
                        blurb={cardBlurb}
                        content={procedure}
                        formFields={formFields}
                    />
                ))}
            </ul>
        </div>
    );
};
