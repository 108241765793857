import { createContext, useCallback, useContext, useMemo, useReducer } from "react";
import { useAsync } from "../../hooks/use-async";
import { reducer } from "./reducer";
import { buildActions } from "./build-actions";

const Context = createContext(null);

export const AnimalsContext = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, []);
    const actions = useMemo(() => buildActions(dispatch), [dispatch]);

    const getAnimals = useCallback(() => actions.getAnimals(), [actions]);

    useAsync(getAnimals, true);

    return <Context.Provider value={[state, actions]}>{children}</Context.Provider>;
};

export const useAnimalsContext = () => {
    const context = useContext(Context);
    if (!context) {
        throw new Error("useAnimalsContext must be used within a AnimalsContext");
    }
    return [...context];
};
