import { useEffect, useState } from "react";
import { Card } from "../Card";
import { Filters, filterFields } from "../Filters";
import { Register } from "../Register";
import { NavLink } from "react-router-dom";
import { useTutorsContext } from "../../contexts/TutorsContext";
import { useAnimalsContext } from "../../contexts/AnimalsContext";
import { useMediaQuery } from "../../hooks/use-media-query";

const formFields = [
    {
        label: "Nome",
        name: "name",
        type: "text",
        width: "300",
        readOnly: true,
    },
    {
        label: "CPF",
        name: "cpf",
        mask: "999.999.999-99",
        type: "text",
        width: "120",
        readOnly: true,
    },
    {
        label: "Telefone",
        type: "tel",
        name: "phone",
        mask: "(99) 99999-9999",
        width: "160",
        readOnly: true,
    },
    {
        label: "Animais",
        name: "animals",
        type: "multiselect",
        options: [],
        multiSelectValue: [],
        width: "350",
        readOnly: true,
    },
    {
        label: "Rua",
        name: "street",
        type: "text",
        width: "260",
        readOnly: true,
    },
    {
        label: "Bairro",
        name: "district",
        type: "text",
        width: "200",
        readOnly: true,
    },
    {
        label: "CEP",
        name: "cep",
        mask: "99999-999",
        type: "text",
        width: "120",
        readOnly: true,
    },
    {
        label: "Número",
        name: "number",
        type: "number",
        width: "80",
        readOnly: true,
    },
    {
        label: "Complemento",
        name: "comp",
        type: "text",
        width: "150",
        readOnly: true,
    },
];

const cardBlurb = (content) => (
    <NavLink to={`/procedimentos?filter_search_name=${content.name.toLowerCase()}`} className="mt-3 link-decoration-effect">
        <span className="text-primary-color fs-7">ver histórico</span>
    </NavLink>
);

export const Tutores = () => {
    const [state, actions] = useTutorsContext();
    const [animalsState] = useAnimalsContext();
    const [filters, setFilters] = useState({});

    const filteredTutors = filterFields(state, filters);

    const small = useMediaQuery("(max-width: 768px)");

    useEffect(() => {
        formFields.forEach((field) => {
            if (field.name === "animals") {
                field.options = animalsState.map((animal) => ({
                    label: animal.name,
                    value: animal.id,
                }));
            }
        });
    }, [animalsState]);

    return (
        <div className="d-flex-column justify-content-start">
            <h1>Tutores</h1>
            <div className={`d-flex ${small ? "flex-column" : ""}`}>
                <Filters setFilters={setFilters} />
                <Register onHandleCreateRegister={actions.createTutor} registerName="Cadastro de Tutor" formFields={formFields} />
            </div>
            <ul className="d-flex-column list-style-none mt-5 p-0 max-width-large">
                {filteredTutors.map((tutor) => (
                    <Card
                        onHandleRemoveCard={actions.removeTutor}
                        onHandleUpdateCard={actions.updateTutor}
                        key={`tutor_parent_${tutor.id}`}
                        blurb={cardBlurb}
                        content={tutor}
                        formFields={formFields}
                    />
                ))}
            </ul>
        </div>
    );
};
