const prefix = "RoomsContext";

export const GET_ROOMS = `${prefix}/GET_ROOMS`;
export const GET_ROOM = `${prefix}/GET_ROOM`;
export const CREATE_ROOM = `${prefix}/CREATE_ROOM`;
export const UPDATE_ROOM = `${prefix}/UPDATE_ROOM`;
export const DELETE_ROOM = `${prefix}/DELETE_ROOM`;
export const SET_ROOMS = `${prefix}/SET_ROOMS`;

export const GET_RESERVATIONS = `${prefix}/GET_RESERVATIONS`;
export const GET_RESERVE = `${prefix}/GET_RESERVE`;
export const CREATE_RESERVE = `${prefix}/CREATE_RESERVE`;
export const UPDATE_RESERVE = `${prefix}/UPDATE_RESERVE`;
export const DELETE_RESERVE = `${prefix}/DELETE_RESERVE`;
export const SET_RESERVATIONS = `${prefix}/SET_RESERVATIONS`;
