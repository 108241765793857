import * as actionTypes from "./action-types";

const removeTutor = async (id) => {
    const response = await fetch(`https://test-donaflorinda.netlify.app/.netlify/functions/delete-tutor/?id=${id}`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
    const data = await response.json();
    return data;
};

const updateTutor = async (id, body) => {
    const response = await fetch(`https://test-donaflorinda.netlify.app/.netlify/functions/update-tutor/?id=${id}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(body),
    });
    const data = await response.json();
    return data;
};

const createTutor = async (body) => {
    const response = await fetch("https://test-donaflorinda.netlify.app/.netlify/functions/create-tutor", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(body),
    });
    const data = await response.json();
    return data;
};

const getTutors = async () => {
    try {
        const response = await fetch("https://test-donaflorinda.netlify.app/.netlify/functions/get-tutors", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        const data = await response.json();
        return data;
    } catch (error) {
        localStorage.removeItem("token");
        window.location.href = "/login";
    }
};

export const buildActions = (dispatch) => {
    return {
        removeTutor: async (id) => {
            await removeTutor(id);
            dispatch({ type: actionTypes.DELETE_TUTOR, payload: id });
        },
        updateTutor: async (id, body) => {
            const data = await updateTutor(id, body);
            dispatch({ type: actionTypes.UPDATE_TUTOR, payload: data });
        },
        createTutor: async (body) => {
            const data = await createTutor(body);
            dispatch({ type: actionTypes.CREATE_TUTOR, payload: data });
        },
        getTutors: async () => {
            const data = await getTutors();
            dispatch({ type: actionTypes.GET_TUTORS, payload: data });
        },
        setTutors: (tutors) => {
            dispatch({ type: actionTypes.SET_TUTORS, payload: tutors });
        },
    };
};
