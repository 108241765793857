import { createContext, useCallback, useContext, useMemo, useReducer } from "react";
import { useAsync } from "../../hooks/use-async";
import { reducer } from "./reducer";
import { buildActions } from "./build-actions";

const RoomContext = createContext(null);
const ReserveContext = createContext(null);

export const RoomsContext = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, []);
    const actions = useMemo(() => buildActions(dispatch), [dispatch]);

    const getRooms = useCallback(() => actions.getRooms(), [actions]);

    useAsync(getRooms, true);

    return <RoomContext.Provider value={[state, actions]}>{children}</RoomContext.Provider>;
};

export const useRoomsContext = () => {
    const context = useContext(RoomContext);
    if (!context) {
        throw new Error("useRoomsContext must be used within a RoomsContext");
    }
    return [...context];
};

export const ReservationsContext = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, []);
    const actions = useMemo(() => buildActions(dispatch), [dispatch]);

    return <ReserveContext.Provider value={[state, actions]}>{children}</ReserveContext.Provider>;
};

export const useReservationsContext = () => {
    const context = useContext(ReserveContext);
    if (!context) {
        throw new Error("useReservationsContext must be used within a ReservationsContext");
    }
    return [...context];
};
