import { createContext, useCallback, useContext, useMemo, useReducer } from "react";
import { useAsync } from "../../hooks/use-async";
import { reducer } from "./reducer";
import { buildActions } from "./build-actions";

const Context = createContext(null);

export const TutorsContext = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, []);
    const actions = useMemo(() => buildActions(dispatch), [dispatch]);

    const getTutors = useCallback(() => actions.getTutors(), [actions]);

    useAsync(getTutors, true);

    return <Context.Provider value={[state, actions]}>{children}</Context.Provider>;
};

export const useTutorsContext = () => {
    const context = useContext(Context);
    if (!context) {
        throw new Error("useTutorsContext must be used within a TutorsContext");
    }
    return [...context];
};
