import * as actionTypes from "./action-types";

const removeProcedure = async (id) => {
    const response = await fetch(`https://test-donaflorinda.netlify.app/.netlify/functions/delete-procedure/?id=${id}`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
    const data = await response.json();
    return data;
};

const updateProcedure = async (id, body) => {
    const response = await fetch(`https://test-donaflorinda.netlify.app/.netlify/functions/update-procedure/?id=${id}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(body),
    });
    const data = await response.json();
    return data;
};

const createProcedure = async (body) => {
    const response = await fetch("https://test-donaflorinda.netlify.app/.netlify/functions/create-procedure", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(body),
    });
    const data = await response.json();
    return data;
};

const getProcedures = async () => {
    try {
        const response = await fetch("https://test-donaflorinda.netlify.app/.netlify/functions/get-procedures", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        const data = await response.json();
        return data;
    } catch (error) {
        localStorage.removeItem("token");
        window.location.href = "/login";
    }
};

export const buildActions = (dispatch) => {
    return {
        removeProcedure: async (id) => {
            await removeProcedure(id);
            dispatch({ type: actionTypes.DELETE_PROCEDURE, payload: id });
        },
        updateProcedure: async (id, body) => {
            const data = await updateProcedure(id, body);
            dispatch({ type: actionTypes.UPDATE_PROCEDURE, payload: data });
        },
        createProcedure: async (body) => {
            let data = await createProcedure(body);
            data.procedure_date = data.procedure_date.split("T")[0];
            dispatch({ type: actionTypes.CREATE_PROCEDURE, payload: data });
        },
        getProcedures: async () => {
            const data = await getProcedures();
            data.forEach((procedure) => {
                procedure.procedure_date = procedure.procedure_date.split("T")[0];
            });
            dispatch({ type: actionTypes.GET_PROCEDURES, payload: data });
        },
        setProcedures: (procedures) => {
            dispatch({ type: actionTypes.SET_PROCEDURES, payload: procedures });
        },
    };
};
