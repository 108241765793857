import { useEffect, useState } from "react";
import { Card } from "../Card";
import { Filters, filterFields } from "../Filters";
import { Register } from "../Register";
import { NavLink } from "react-router-dom";
import { useTutorsContext } from "../../contexts/TutorsContext";
import { useMediaQuery } from "../../hooks/use-media-query";
import { useRoomsContext } from "../../contexts/HotelContext";

const formFields = [
    {
        label: "Nome",
        name: "name",
        type: "text",
        width: "300",
        readOnly: true,
    },
    {
        label: "Data de nascimento",
        name: "birthdate",
        type: "date",
        width: "150",
        readOnly: true,
    },
    {
        label: "Castrado",
        name: "castrated",
        type: "dropdown",
        options: [
            { label: "Sim", value: true },
            { label: "Não", value: false },
        ],
        width: "150",
        readOnly: true,
    },
    {
        label: "Raça",
        name: "race",
        type: "text",
        width: "150",
        readOnly: true,
    },
    {
        label: "Sexo",
        name: "sex",
        type: "dropdown",
        options: [
            { label: "Macho", value: "M" },
            { label: "Fêmea", value: "F" },
        ],
        width: "100",
        readOnly: true,
    },
    {
        label: "Vacinas",
        name: "vaccine",
        type: "dropdown",
        options: [
            { label: "Sim", value: true },
            { label: "Não", value: false },
        ],
        width: "80",
        readOnly: true,
    },
    {
        label: "Se sim, quais?",
        name: "vaccine_details",
        type: "text",
        width: "240",
        readOnly: true,
    },
    {
        label: "Trouxe ração",
        name: "bringFood",
        type: "dropdown",
        options: [
            { label: "Sim", value: true },
            { label: "Não", value: false },
        ],
        width: "80",
        readOnly: true,
    },
    {
        label: "Trouxe cama",
        name: "bringBed",
        type: "dropdown",
        options: [
            { label: "Sim", value: true },
            { label: "Não", value: false },
        ],
        width: "80",
        readOnly: true,
    },
    {
        label: "Data de entrada",
        name: "checkIn",
        type: "date",
        width: "150",
        readOnly: true,
    },
    {
        label: "Data de saída",
        name: "checkOut",
        type: "date",
        width: "150",
        readOnly: true,
    },
    {
        label: "Tutores",
        name: "tutors",
        type: "multiselect",
        options: [],
        multiSelectValue: [],
        width: "440",
        readOnly: true,
    },
    {
        label: "Observações",
        name: "details",
        type: "textarea",
        width: "670",
        readOnly: true,
    },
];

const cardBlurb = (content) => (
    <NavLink to={`/procedimentos?filter_search_name=${content.name.toLowerCase()}`} className="mt-3 link-decoration-effect">
        <span className="text-primary-color fs-7">ver histórico</span>
    </NavLink>
);

export const Hotel = () => {
    const [state, actions] = useRoomsContext();
    const [tutorsState] = useTutorsContext();
    const [filters, setFilters] = useState({});

    const filteredRooms = filterFields(state, filters);

    const small = useMediaQuery("(max-width: 768px)");

    useEffect(() => {
        formFields.forEach((field) => {
            if (field.name === "tutors") {
                field.options = tutorsState.map((tutor) => ({
                    label: tutor.name,
                    value: tutor.id,
                }));
            }
        });
        console.log(state);
    }, [tutorsState, state]);

    return (
        <div>
            <h1>Hotel</h1>
            <div className={`d-flex ${small ? "flex-column" : ""}`}>
                <Filters setFilters={setFilters} />
                <Register onHandleCreateRegister={actions.createRoom} registerName="Cadastro de Hóspede" formFields={formFields} />
            </div>
            <ul className="d-flex-column list-style-none mt-5 p-0 max-width-large">
                {filteredRooms.map((room) => (
                    <Card
                        onHandleRemoveCard={actions.removeRoom}
                        onHandleUpdateCard={actions.updateRoom}
                        key={`room_parent_${room.id}`}
                        blurb={cardBlurb}
                        content={room}
                        formFields={formFields}
                    />
                ))}
            </ul>
        </div>
    );
};
