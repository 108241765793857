import * as actionTypes from "./action-types";
const initialState = [];

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ROOMS:
        case actionTypes.GET_ROOM:
            return Array.isArray(action.payload) ? action.payload : [];
        case actionTypes.CREATE_ROOM:
            return [action.payload, ...state];
        case actionTypes.CREATE_RESERVE:
            return state.map((room) => {
                if (room.id === action.payload.room_id) {
                    room.reservations.push(action.payload);
                }
                return room;
            });
        case actionTypes.UPDATE_ROOM:
            return state.map((room) => {
                if (room.id === action.payload.id) {
                    return action.payload;
                }
                return room;
            });
        case actionTypes.UPDATE_RESERVE:
            return state.map((room) => {
                room.reservations = room.reservations.map((reserve) => {
                    if (reserve.id === action.payload.id) {
                        return action.payload;
                    }
                    return reserve;
                });
                return room;
            });
        case actionTypes.SET_ROOMS:
            return action.payload;
        case actionTypes.DELETE_ROOM:
            return state.filter((room) => room.id !== action.payload);
        default:
            return state;
    }
};
