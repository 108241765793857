import * as actionTypes from "./action-types";
const initialState = [];

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ANIMALS:
        case actionTypes.GET_ANIMAL:
            return Array.isArray(action.payload) ? action.payload : [];
        case actionTypes.CREATE_ANIMAL:
            return [action.payload, ...state];
        case actionTypes.UPDATE_ANIMAL:
            return state.map((animal) => {
                if (animal.id === action.payload.id) {
                    return action.payload;
                }
                return animal;
            });
        case actionTypes.SET_ANIMALS:
            return action.payload;
        case actionTypes.DELETE_ANIMAL:
            return state.filter((animal) => animal.id !== action.payload);
        default:
            return state;
    }
};
