import { Outlet } from "react-router-dom";
import { Navbar } from "../../components/Navbar";
import { useCallback, useEffect } from "react";
import { useAsync } from "../../hooks/use-async";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";

const menus = [
    {
        title: "Tutores",
        link: "/tutores",
    },
    {
        title: "Animais",
        link: "/animais",
    },
    {
        title: "Procedimentos",
        link: "/procedimentos",
    },
    {
        title: "Produtos",
        link: "/produtos",
    },
    {
        title: "Hotel",
        link: "/hotel",
    },
];

export const Home = () => {
    const handleGoToTop = useCallback(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (!token) {
            window.location.href = "/login";
        }
    }, []);

    return (
        <div className="d-flex min-height-100vh">
            <Navbar menus={menus} />
            <main className="py-4 px-5 vw-100">
                <Outlet />
                <button
                    type="button"
                    onClick={() => {
                        handleGoToTop();
                    }}
                    className="go-to-button position-fixed transition-all circular-button flex-center border-primary-color border-solid rounded-circle background-primary-color">
                    <FontAwesomeIcon icon={faArrowUp} />
                </button>
            </main>
        </div>
    );
};
