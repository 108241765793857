import { useForm, Controller } from "react-hook-form";
import InputMask from "react-input-mask";
import Select, { components } from "react-select";
import { useMediaQuery } from "../../hooks/use-media-query";

export const MenuList = (props) => {
    const { children, ...rest } = props;
    return <components.MenuList {...rest}>{Array.isArray(children) ? children.slice(0, 5) : children}</components.MenuList>;
};

export const Input: any = ({ inputConfig, readOnly = false, register = null, control, handleInputChange = null }) => {
    const { label, type, width, options, name, mask = "", multiSelectValue = [], defaultValue } = inputConfig;

    const small = useMediaQuery("(max-width: 768px)");

    const localForm = useForm();
    const formControl = control || localForm.control;

    return (
        <div style={{ width: small ? "" : `${width}px` }} className={`${type === "hidden" ? "d-none" : "d-flex-column"}`}>
            {label && <label>{label}</label>}
            {type === "dropdown" ? (
                <select
                    {...(register ? register(name) : {})}
                    disabled={readOnly}
                    onChange={handleInputChange}
                    className="border-primary-color border-input rounded-pill px-2 py-0-157 fs-7 lh-sm text-primary-color">
                    {options.map((option, index) => (
                        <option key={`option_${index}`} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            ) : type === "textarea" ? (
                <textarea
                    {...(register ? register(name) : {})}
                    readOnly={readOnly}
                    className={`border-primary-color border-input px-2 py-1 fs-7 lh-sm text-primary-color rounded height-large`}
                />
            ) : type === "multiselect" ? (
                <Controller
                    name="select"
                    control={control}
                    defaultValue={multiSelectValue}
                    render={({ field }) => (
                        <Select
                            styles={{
                                control: (styles) => ({
                                    ...styles,
                                    width: `${small ? "100%" : width + "px"}`,
                                    minHeight: "27.5px",
                                    borderRadius: "50rem",
                                    border: "1px solid var(--border-primary-color) !important",
                                    padding: "0",
                                    fontSize: "0.875rem",
                                    lineHeight: "1.25",
                                    cursor: "pointer",
                                    boxShadow: "none",
                                    outline: "none !important",
                                    "&:hover": {
                                        border: "1px solid #B1B1B1",
                                    },
                                    "&:focus-visible": {
                                        outline: "none !important",
                                    },
                                }),
                            }}
                            placeholder="Comece a digitar para procurar"
                            isDisabled={readOnly}
                            {...field}
                            options={options}
                            isMulti
                            components={{ MenuList }}
                        />
                    )}
                />
            ) : (
                <Controller
                    control={formControl}
                    name={name}
                    defaultValue={defaultValue || ""}
                    render={({ field }) => (
                        <InputMask
                            {...field}
                            mask={mask}
                            readOnly={readOnly}
                            className={`border-primary-color border-input px-2 py-1 fs-7 lh-sm text-primary-color rounded-pill`}
                            type={type}
                            onChange={(event) => {
                                field.onChange(event);
                                if (handleInputChange) {
                                    handleInputChange(event);
                                }
                            }}
                        />
                    )}
                />
            )}
        </div>
    );
};
