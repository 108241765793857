import React from "react";
import ReactDOM from "react-dom/client";
import "./styles.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import { Home } from "./templates/Home";
import { Login } from "./templates/Login";
import { Tutores } from "./components/Tutores";
import { Animais } from "./components/Animais";
import { Procedimentos } from "./components/Procedimentos";
import { Produtos } from "./components/Produtos";
import { Hotel } from "./components/Hotel";
import { ToastContainer } from "react-toastify";
import { PrivateRoute } from "./components/PrivateRoute";

import { ProductsContext } from "./contexts/ProductsContext";
import { TutorsContext } from "./contexts/TutorsContext";
import { AnimalsContext } from "./contexts/AnimalsContext";
import { ProceduresContext } from "./contexts/ProceduresContext";
import { ReservationsContext, RoomsContext } from "./contexts/HotelContext";

const rootElement = document.getElementById("root");
if (rootElement) {
    const root = ReactDOM.createRoot(rootElement);

    root.render(
        <React.StrictMode>
            <BrowserRouter>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/" element={<PrivateRoute />}>
                        <Route path="/" element={<Home />}>
                            <Route
                                path="tutores"
                                element={
                                    <TutorsContext>
                                        <AnimalsContext>
                                            <Tutores />
                                        </AnimalsContext>
                                    </TutorsContext>
                                }
                            />
                            <Route
                                path="animais"
                                element={
                                    <AnimalsContext>
                                        <TutorsContext>
                                            <Animais />
                                        </TutorsContext>
                                    </AnimalsContext>
                                }
                            />
                            <Route
                                path="procedimentos"
                                element={
                                    <ProceduresContext>
                                        <AnimalsContext>
                                            <Procedimentos />
                                        </AnimalsContext>
                                    </ProceduresContext>
                                }
                            />
                            <Route
                                path="produtos"
                                element={
                                    <ProductsContext>
                                        <Produtos />
                                    </ProductsContext>
                                }
                            />
                            <Route
                                path="hotel"
                                element={
                                    <RoomsContext>
                                        <TutorsContext>
                                            <Hotel />
                                        </TutorsContext>
                                    </RoomsContext>
                                }
                            />
                        </Route>
                    </Route>
                </Routes>
                <ToastContainer />
            </BrowserRouter>
        </React.StrictMode>
    );
}
