import { toast } from "react-toastify";
import { CustomToast } from "../components/CustomToast";

export const useToast = () => {
    const notify = (message: string, type: string) => {
        switch (type) {
            case "success": {
                toast.success(<CustomToast closeToast={toast.dismiss} message={message} />);
                break;
            }
            case "error": {
                toast.error(<CustomToast closeToast={toast.dismiss} message={message} />);
                break;
            }
            case "info": {
                toast.info(<CustomToast closeToast={toast.dismiss} message={message} />);
                break;
            }
        }
    };

    return { notify };
};
