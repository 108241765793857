import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input } from "../Input";
import { faRedoAlt, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useMediaQuery } from "../../hooks/use-media-query";

export const filterFields = (fields, filters, customFilterCallback = null) => {
    return fields.filter((field) => {
        let matches = true;

        if (filters.filter_search) {
            matches = matches && field.name.toLowerCase().includes(filters.filter_search.toLowerCase());
        }

        if (filters.filter_startdate && filters.filter_enddate) {
            let startDate = new Date(filters.filter_startdate);
            startDate.setHours(0, 0, 0, 0);
            let startDateTimestamp = startDate.getTime() + 86400000;

            let endDate = new Date(filters.filter_enddate);
            endDate.setHours(0, 0, 0, 0);
            let endDateTimestamp = endDate.getTime() + 86400000;

            let createdDate = new Date(field.created_date);
            createdDate.setHours(0, 0, 0, 0);
            let createdDateTimestamp = createdDate.getTime();

            matches = matches && createdDateTimestamp >= startDateTimestamp && createdDateTimestamp <= endDateTimestamp;
        }

        if (customFilterCallback) {
            matches = matches && customFilterCallback(field);
        }

        return matches;
    });
};

export const Filters = ({ customFilters = [], setFilters }) => {
    const { control, register, setValue, getValues, reset } = useForm({
        defaultValues: {
            filter_search: "",
            filter_startdate: "",
            filter_enddate: "",
            ...customFilters.reduce((acc, filter) => ({ ...acc, [filter.name]: "" }), {}),
        },
    });

    const small = useMediaQuery("(max-width: 768px)");

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const handleInputChange = (event) => {
        let value = event.target.value;
        if (value === "false" || value === "true") {
            value = value === "true";
        }
        setValue(event.target.name, value);
        setFilters(getValues());
    };

    const handleReset = () => {
        reset();
        setFilters(getValues());
    };

    useEffect(() => {
        Array.from(queryParams).forEach(([key, value]) => {
            setValue(key, value);
        });
        setFilters(getValues());
    }, []);

    return (
        <div className={`d-flex justify-content-between ${small ? "flex-column" : ""}`}>
            <div className="d-flex-column position-relative me-4">
                <Input
                    control={control}
                    register={register}
                    handleInputChange={handleInputChange}
                    inputConfig={{ label: "Procurar", name: "filter_search", type: "text", width: "200" }}
                />
                <FontAwesomeIcon icon={faSearch} className="position-absolute top-50 end-0 translate-middle-y-15 me-2" />
            </div>
            <div className="d-flex-column">
                <div className={`d-flex ${small ? "flex-column" : ""}`}>
                    <div className="me-4">
                        <Input
                            control={control}
                            register={register}
                            handleInputChange={handleInputChange}
                            inputConfig={{ label: "Data inicial", name: "filter_startdate", type: "date", width: "150" }}
                        />
                    </div>
                    <div className="me-4">
                        <Input
                            control={control}
                            register={register}
                            handleInputChange={handleInputChange}
                            inputConfig={{ label: "Data final", name: "filter_enddate", type: "date", width: "150" }}
                        />
                    </div>
                    {customFilters.map((filter, index) => (
                        <div key={`custom_filter_${index}`} className="me-4">
                            <Input control={control} register={register} handleInputChange={handleInputChange} inputConfig={filter} />
                        </div>
                    ))}
                    <div className="d-flex-column me-4">
                        <label>Redefinir</label>
                        <button
                            onClick={handleReset}
                            className="transition-all circular-button flex-center border-primary-color border-solid rounded-circle background-primary-color">
                            <FontAwesomeIcon icon={faRedoAlt} />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
