import logo from "../../assets/logo.png";
import { Input } from "../../components/Input";
import { useForm } from "react-hook-form";
import { useToast } from "../../hooks/use-toast";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const inputs = [
    {
        label: "Email",
        name: "login",
        type: "email",
        width: 310,
    },
    {
        label: "Senha",
        name: "password",
        type: "password",
        width: 310,
    },
];

export const Login = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const { control, register, handleSubmit } = useForm();
    const { notify } = useToast();

    const onSubmit = async (data: any) => {
        setIsLoading(true);
        notify("Fazendo login...", "info");
        try {
            const response = await fetch("https://test-donaflorinda.netlify.app/.netlify/functions/login", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });
            const responseData = await response.json();
            if (responseData.token) {
                localStorage.setItem("token", responseData.token);
                notify("Login efetuado com sucesso!", "success");
                window.location.href = "/";
            } else {
                notify("Usuário e/ou senha inválidos!", "error");
            }
            setTimeout(() => {
                setIsLoading(false);
            }, 1000);
        } catch (error) {
            setTimeout(() => {
                setIsLoading(false);
            }, 1000);
            notify("Erro ao fazer login, tente novamente mais tarde!", "error");
        }
    };

    const handlePassword = () => {
        setShowPassword(!showPassword);
    };

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            window.location.href = "/";
        }
    }, []);

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex-center flex-column vh-100">
            <img className="max-width-image w-100 border-w-10 border-secondary-color border-solid rounded-circle" src={logo} alt="Logo" />
            {inputs.map((inputConfig, index) =>
                inputConfig.type === "email" ? (
                    <Input control={control} register={register} key={`login_input_${index}`} inputConfig={inputConfig} />
                ) : (
                    <div className="d-flex-column position-relative">
                        <Input
                            control={control}
                            register={register}
                            key={`login_input_${index}`}
                            inputConfig={{ ...inputConfig, type: showPassword ? "text" : "password" }}
                        />
                        <button
                            type="button"
                            onClick={() => handlePassword()}
                            className="flex-center transition-all bg-transparent border-0 custom-button no-button-effects position-absolute top-50 end-0 translate-middle-y-15 me-1">
                            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                        </button>
                    </div>
                )
            )}
            <button
                disabled={isLoading}
                type="submit"
                className="transition-all fs-7 mt-4 px-5 pb-1 rounded-pill border-0 text-secondary-color fw-semibold background-button-color">
                Login
            </button>
        </form>
    );
};
