import { Input } from "../Input";
import { Separator } from "../Separator";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-modal";
import { faAngleDown, faAngleRight, faCheckCircle, faEdit, faPaw, faPencil, faTrash, faUser, faX } from "@fortawesome/free-solid-svg-icons";
import { useRef, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useToast } from "../../hooks/use-toast";
import { useForm } from "react-hook-form";
import { useMediaQuery } from "../../hooks/use-media-query";

export const Card = ({
    content,
    formFields,
    onHandleRemoveCard = null,
    onHandleUpdateCard = null,
    blurb = null,
    havePayment = false,
    customInfo = null,
}) => {
    let { id, name, paid, created_date, animalsValue = [], tutorsValue = [] } = content;

    const initialContentRef = useRef(content);
    const [isHidden, setIsHidden] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
    const [isLoadingRequest, setIsLoadingRequest] = useState(false);

    const animalsRef = useRef(animalsValue);
    const formFieldsStateRef = useRef(formFields);
    const tutorsRef = useRef(tutorsValue);

    const small = useMediaQuery("(max-width: 768px)");
    const { notify } = useToast();
    const { control, register, handleSubmit, reset } = useForm({
        defaultValues: content,
    });

    const toggleIsHidden = () => {
        if (animalsRef.current) {
            formFieldsStateRef.current.forEach((field) => {
                if (field.name === "animals") {
                    field.multiSelectValue = animalsRef.current;
                }
            });
        }
        if (tutorsRef.current) {
            formFieldsStateRef.current.forEach((field) => {
                if (field.name === "tutors") {
                    field.multiSelectValue = tutorsRef.current;
                }
            });
        }
        setIsHidden(!isHidden);
    };

    const toggleIsEditing = () => {
        setIsEditing(!isEditing);
    };

    const handlePayment = async () => {
        try {
            if (!paid) {
                setIsLoadingRequest(true);
                notify("Pagando...", "info");
                const { animalsValue, animals, ...restOfContent } = content;
                await onHandleUpdateCard(id, { ...restOfContent, paid: true, animals });
                initialContentRef.current = { ...restOfContent, paid: true, animals };
                setIsLoadingRequest(false);
                notify("Pagamento realizado com sucesso!", "success");
            }
        } catch (error) {
            setIsLoadingRequest(false);
            notify("Erro ao pagar, tente novamente mais tarde!", "error");
        }
    };

    const handleCancel = () => {
        toggleIsEditing();
        reset(initialContentRef.current);
    };

    const handleRemoveCard = async () => {
        try {
            setIsLoadingRequest(true);
            notify("Removendo...", "info");
            setConfirmationModalIsOpen(false);
            await onHandleRemoveCard(id);
            setIsLoadingRequest(false);
            notify("Removido com sucesso!", "success");
        } catch (error) {
            setIsLoadingRequest(false);
            notify("Erro ao salvar, tente novamente mais tarde!", "error");
        }
    };

    const onSubmit = async (data) => {
        try {
            setIsLoadingRequest(true);
            notify("Atualizando...", "info");
            formFields.forEach((field) => {
                if (field.type === "number") {
                    data[field.name] = +data[field.name];
                }
                if (field.type === "multiselect") {
                    if (data.select) {
                        if (field.name === "animals") {
                            animalsRef.current = data.select;
                        }
                        if (field.name === "tutors") {
                            tutorsRef.current = data.select;
                        }
                        data[field.name] = data.select.map((item) => item.value);
                        delete data[`${field.name}Value`];
                        delete data.select;
                    }
                    field.multiSelectValue = data[field.name];
                }
            });
            for (let key in data) {
                if (data[key] === "true" || data[key] === "false") {
                    data[key] = data[key] === "true";
                }
            }
            await onHandleUpdateCard(id, data);
            initialContentRef.current = data;
            setIsLoadingRequest(false);
            notify("Salvo com sucesso!", "success");
            toggleIsEditing();
        } catch (error) {
            setIsLoadingRequest(false);
            notify("Erro ao salvar, tente novamente mais tarde!", "error");
        }
    };

    return (
        <li>
            <form className="d-flex-column mb-5" onSubmit={handleSubmit(onSubmit)}>
                <div className="z-2 d-flex justify-content-between position-relative p-3 bg-white border-solid border-width-5 border-primary-color rounded-pill">
                    <div className="flex-center">
                        <button
                            disabled={isLoadingRequest}
                            type="button"
                            onClick={() => toggleIsHidden()}
                            className="flex-center transition-all bg-transparent border-0 custom-button no-button-effects max-width-card">
                            <FontAwesomeIcon icon={isHidden ? faAngleDown : faAngleRight} className="fs-5" />
                            <div className="text-primary-color fw-bold fs-5 ms-2 text-overflow-ellipsis">
                                <span className="text-capitalize">{name}</span>
                                {havePayment && <FontAwesomeIcon icon={faCheckCircle} className={`fs-5 ms-2 ${paid ? "is-paid" : "is-not-paid"}`} />}
                            </div>
                        </button>
                        {isEditing && <FontAwesomeIcon icon={faPencil} className="fs-5 ms-2" />}
                    </div>
                    <div className="flex-center">
                        <button
                            disabled={isLoadingRequest}
                            type="button"
                            onClick={() => toggleIsEditing()}
                            className="flex-center transition-all bg-transparent border-0 custom-button">
                            <FontAwesomeIcon icon={faEdit} className="fs-5 me-2" />
                        </button>
                        <button
                            disabled={isLoadingRequest}
                            type="button"
                            className="flex-center transition-all bg-transparent border-0 custom-button">
                            <FontAwesomeIcon onClick={() => setConfirmationModalIsOpen(true)} icon={faTrash} className="fs-5" />
                        </button>
                        <Modal
                            isOpen={confirmationModalIsOpen}
                            onRequestClose={() => setConfirmationModalIsOpen(false)}
                            contentLabel="Example Modal"
                            style={{
                                overlay: {
                                    backgroundColor: "rgba(0, 0, 0, 0.75)",
                                    zIndex: "3",
                                },
                                content: {
                                    width: "500px",
                                    top: "50%",
                                    left: "50%",
                                    right: "auto",
                                    bottom: "auto",
                                    transform: "translate(-50%, -50%)",
                                    backgroundColor: "var(--background-primary-color)",
                                    position: "relative",
                                    border: "1px solid var(--border-primary-color)",
                                    display: "flex",
                                    justifyContent: "start",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    borderRadius: "20px",
                                },
                            }}>
                            <h2 className="text-primary-color fw-bold">Tem certeza?</h2>
                            <div className="align-self-center mt-4">
                                <button
                                    disabled={isLoadingRequest}
                                    type="button"
                                    onClick={() => setConfirmationModalIsOpen(false)}
                                    className="text-black border-0 rounded-pill background-cancel-button-color fs-7 px-4 me-4">
                                    Cancelar
                                </button>
                                <button
                                    disabled={isLoadingRequest}
                                    type="button"
                                    onClick={() => handleRemoveCard()}
                                    className="text-black border-0 rounded-pill background-confirm-button-color fs-7 px-4">
                                    Confirmar
                                </button>
                            </div>
                            <button
                                type="button"
                                className="close-button-modal position-absolute bg-transparent border-0 fs-4 custom-button"
                                onClick={() => setConfirmationModalIsOpen(false)}>
                                <FontAwesomeIcon icon={faX} />
                            </button>
                        </Modal>
                    </div>
                </div>
                {isHidden && (
                    <div className="position-relative z-1 background-secondary-color align-self-center n-mt-1 w-95 p-3 border-solid border-primary-color rounded">
                        <div className="d-flex justify-content-between lh-3">
                            <span className="text-primary-color fs-7">Criado em: {new Date(created_date).toLocaleDateString("pt-BR")}</span>
                            {customInfo && customInfo(content)}
                            {isEditing && <span className="text-primary-color fw-bold fs-6">*editando</span>}
                        </div>
                        <div className="d-flex flex-wrap mt-4 justify-content-start">
                            {formFields.map((field) => (
                                <div key={`form_field_${field.name}`} className={`mx-3 my-2 ${small ? "w-100" : ""}`}>
                                    <Input control={control} register={register} inputConfig={field} readOnly={!isEditing} />
                                </div>
                            ))}
                        </div>
                        {animalsRef.current && animalsRef.current.length > 0 && (
                            <div className="d-flex-column align-items-center mt-4">
                                <span className="fw-bold fs-4 text-primary-color">Animais</span>
                                <Separator width="25" />
                                <div className="flex-center flex-wrap">
                                    {animalsRef.current.map((animal, index) => (
                                        <NavLink
                                            key={`animal_${index}`}
                                            to={`/animais?filter_search=${animal.label.toLowerCase()}`}
                                            className="me-2 text-decoration-none bg-transparent border-0 custom-button transition-all d-flex">
                                            <FontAwesomeIcon icon={faPaw} className="fs-5 me-1" />
                                            <span className="text-capitalize text-primary-color fw-medium text-overflow-ellipsis">
                                                {animal.label}
                                            </span>
                                        </NavLink>
                                    ))}
                                </div>
                            </div>
                        )}
                        {tutorsRef.current && tutorsRef.current.length > 0 && (
                            <div className="d-flex-column align-items-center mt-4">
                                <span className="fw-bold fs-4 text-primary-color">Tutores</span>
                                <Separator width="25" />
                                <div className={`flex-wrap ${small ? "flex-column justify-content-start align-items-start" : "flex-center"}`}>
                                    {tutorsRef.current.map((tutor, index) => (
                                        <NavLink
                                            key={`tutor_${index}`}
                                            to={`/tutores?filter_search=${tutor.label.toLowerCase()}`}
                                            className="me-2 text-decoration-none bg-transparent border-0 custom-button transition-all d-flex max-width-link-relations">
                                            <FontAwesomeIcon icon={faUser} className="fs-5 me-1" />
                                            <span className="text-capitalize text-primary-color fw-medium text-overflow-ellipsis">{tutor.label}</span>
                                        </NavLink>
                                    ))}
                                </div>
                            </div>
                        )}
                        <div className="d-flex justify-content-between">
                            {blurb && blurb(content, handlePayment)}
                            {isEditing && (
                                <div className="flex-center">
                                    <button
                                        disabled={isLoadingRequest}
                                        type="button"
                                        onClick={() => handleCancel()}
                                        className="text-black border-0 rounded-pill background-cancel-button-color fs-7 px-4 me-4">
                                        Cancelar
                                    </button>
                                    <button
                                        disabled={isLoadingRequest}
                                        type="submit"
                                        className="text-black border-0 rounded-pill background-confirm-button-color fs-7 px-4">
                                        Salvar
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </form>
        </li>
    );
};
