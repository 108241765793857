import * as actionTypes from "./action-types";

const removeAnimal = async (id) => {
    const response = await fetch(`https://test-donaflorinda.netlify.app/.netlify/functions/delete-animal/?id=${id}`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });
    const data = await response.json();
    return data;
};

const updateAnimal = async (id, body) => {
    const response = await fetch(`https://test-donaflorinda.netlify.app/.netlify/functions/update-animal/?id=${id}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(body),
    });
    const data = await response.json();
    return data;
};

const createAnimal = async (body) => {
    const response = await fetch("https://test-donaflorinda.netlify.app/.netlify/functions/create-animal", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(body),
    });
    const data = await response.json();
    return data;
};

const getAnimals = async () => {
    try {
        const response = await fetch("https://test-donaflorinda.netlify.app/.netlify/functions/get-animals", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        const data = await response.json();
        return data;
    } catch (error) {
        localStorage.removeItem("token");
        window.location.href = "/login";
    }
};

export const buildActions = (dispatch) => {
    return {
        removeAnimal: async (id) => {
            await removeAnimal(id);
            dispatch({ type: actionTypes.DELETE_ANIMAL, payload: id });
        },
        updateAnimal: async (id, body) => {
            const data = await updateAnimal(id, body);
            dispatch({ type: actionTypes.UPDATE_ANIMAL, payload: data });
        },
        createAnimal: async (body) => {
            const data = await createAnimal(body);
            dispatch({ type: actionTypes.CREATE_ANIMAL, payload: data });
        },
        getAnimals: async () => {
            const data = await getAnimals();
            dispatch({ type: actionTypes.GET_ANIMALS, payload: data });
        },
        setAnimals: (animals) => {
            dispatch({ type: actionTypes.SET_ANIMALS, payload: animals });
        },
    };
};
